@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600;800&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif, Poppins;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.apexcharts-menu{
  background-color: #27293d!important;
}
.apexcharts-menu-item{
  background-color: #ffffff!important;
  color: #27293d!important;
}
.apexcharts-menu-item:hover{
  background-color: #27293d!important;
  color: #ffffff!important;
}

.apexcharts-tooltip-title{
  background-color: #27293d!important;
  color: #ffffff!important;
}
.apexcharts-tooltip{
  background-color: #27293d!important;
  color: #ffffff!important;
}

.home-main-content {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  background: linear-gradient(rgba(52,41,49,0.8), rgba(26, 33, 74, 0.8)), url('/image/content/background-main.jpg') no-repeat bottom;
  background-size: cover;
}
.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}







